var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ca4f270a8fad3116f6d21602bfd02e069a199648"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as SentryExtraIntegrations from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_MODE,
  integrations: [
    new SentryExtraIntegrations.ExtraErrorData({
      depth: 3,
    }),
  ],
  autoSessionTracking: false,
  beforeBreadcrumb: (breadcrumb) => {
    if (
      breadcrumb.category === 'http' &&
      /^http:\/\/[^:]+:8126\//.test(breadcrumb.data?.url)
    ) {
      // ignore requests sent to the Datadog agent, these just clutter the breadcrumbs
      return null;
    }

    return breadcrumb;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
